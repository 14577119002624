module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"thomasliu-resume","short_name":"thomasliu-resume","start_url":"/","background_color":"hsla(0,0%,100%,.95)","theme_color":"hsla(0,0%,100%,.95)","display":"minimal-ui","icon":"src/images/edmunds-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"05bdc87fcdc469dcf89507123ec8fdc9"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
